import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo";

const Page = () => {

  return (
    <Layout pageTitle="Tooth Whitening">
      <SEO title="Tooth Whitening"/>
      <div className="two-col-1">
        <h2 className="section-title">Cosmetic tooth whitening</h2>
        <p className="general-text">Have you ever thought about whitening your teeth to help improve their appearance?
          Do you have a special occasion such as a wedding or party in the pipeline? Tooth whitening could be for
          you.</p>
        <p className="general-text">Tooth whitening is a fantastic way to improve the appearance of your teeth. It is
          safe, simple to carry out, suitable for the majority of people and you can expect results in just a few days.
          At Pipers Dental Practice we offer home whitening, which is the most reliable and effective means of whitening
          your teeth.</p>
        <p className="general-text">We take impressions of your teeth and whitening trays are professionally custom-made
          for you. We show you how to apply whitening gel in the trays and then you wear them in the comfort of your own
          home for two to four weeks. Most people prefer to wear the trays at night.</p>
        <br/>
        <h2 className="section-title">How the process works</h2>
        <p className="general-text">Firstly you will need to have a consultation with your dentist. Whitening is
          suitable for the majority of people, but not everyone, which is why it is essential you seek professional
          guidance before starting. You may also require some preliminary treatment before you are ready to start the
          whitening process, which we can discuss with you during your initial appointment.</p>
        <p className="general-text">Once you are happy to proceed, we take impressions which are then used to make
          professionally fitting whitening trays for you.</p>
        <p className="general-text">Your whitening kit will be ready within a week and we can show you exactly how to
          use the whitening gel in your trays. Photographs may be taken and a shade number is assigned to your teeth so
          we have a record of how your teeth look before they are whitened.</p>
        <p className="general-text">Both during and after the whitening course it is important to cut down on
          stain-forming foods and drinks (coffee, tea, herbal tea, red wine and curry) in order to achieve and maintain
          the best possible result. You can "top up" with whitening gel in your trays at a later date if you wish.
          Smoking greatly counteracts the effects of tooth whitening and, for this reason it is not advisable for
          smokers.</p>
        <p className="general-text">Your dentist will recommend a toothpaste to help reduce sensitivity and you should
          start using it a few days in advance of wearing the whitening trays.</p>
        <p className="general-text">After approximately two weeks of wearing the trays overnight, you have the option of
          having a review appointment with us to track your progress. Throughout the whole process you will have our
          full support. If you have any questions or concerns during the treatment you are welcome to contact the
          practice and we will be delighted to assist.</p>
        <p className="general-text">Once you have achieved your desired results, you have the option of a follow-up
          appointment where we will assess if you need any other treatment to complement the end result.</p>
        <p className="general-text">Ensure that you keep your whitening trays, as in 12-18 months you may wish to "top
          up" your teeth with extra gel for a special occasion.</p>
      </div>
      <div className="two-col-2">
        <h2 className="section-title">Frequently asked questions</h2>
        <br/><h3 className="info-title">Does it really work?</h3>
        <p className="general-text">Yes it does, although the amount your teeth whiten depends on a variety of factors,
          such as how dark your teeth are to begin with, how much tea or coffee you drink during the treatment and how
          long you whiten them for in terms of hours a day and number of days. We can review you during and after you
          have completed the whitening course to see how you are getting on. Before and after photographs can be taken
          so you can fully see the effects of the whitening.</p>
        <br/><h3 className="info-title">Will the whitening gel damage my enamel?</h3>
        <p className="general-text">Having professional tooth whitening does NOT damage your tooth enamel.</p>
        <br/><h3 className="info-title">Will it whiten my front crowns and fillings?</h3>
        <p className="general-text">Only natural tooth tissue is capable of being whitened so your front fillings and
          crowns will not be affected. This will all be discussed at your initial consultation, and if any crowns or
          fillings require changing after you have completed the whitening course then this can be done. Everyone is
          different and you will have a tailor-made treatment plan before you commence whitening, so you will be aware
          if any further treatment will be required right from the start.</p>
        <br/><h3 className="info-title">What can I do about the sensitivity?</h3>
        <p className="general-text">Temporary sensitivity is a known side effect of tooth whitening and affects up to
          50% of people who carry out whitening. We recommend that you brush with a sensitive toothpaste before and
          during treatment. If you experience sensitivity, it is only transient and does not mean that the whitening gel
          is harming your teeth.</p>
        <br/><h3 className="info-title">How long does it take?</h3>
        <p className="general-text">This depends on how white you want your teeth to become and what colour they are to
          begin with. Effects are noticeable after just a few days but for the optimum result we recommend you whiten
          them for approximately four to six weeks. If you are unable to wear the trays for as long as overnight or if
          you need to alternate the nights you wear the trays then the treatment will take a little longer.</p>
        <br/><h3 className="info-title">How white will my teeth become?</h3>
        <p className="general-text">The beauty of home whitening is that you are in control of how long you wear the
          trays for and, therefore, how white your teeth become. We can also assess you as you go along to track your
          progress if you so wish.</p>
        <br/><h3 className="info-title">How much does it cost?</h3>
        <p className="general-text">At Pipers Dental Practice tooth whitening costs &pound;360 and this includes your
          consultation, whitening trays, whitening gel and any other follow-up appointments are also included in this
          fee.</p>
        <br/><h3 className="info-title">Can't I just buy whitening gel "over-the-counter" or on the internet?</h3>
        <p className="general-text">We strongly advise you against doing this as many of the non-licenced whitening
          products contain varying amounts of acid, which can be extremely damaging to your teeth and gums. In addition,
          the effects of these products are not as reliable as those of the licenced whitening products that only dental
          professionals are legally allowed to prescribe.</p>
        <br/><h3 className="info-title">Is there an age limit?</h3>
        <p className="general-text">People from the age of approximately 25 years old can whiten their teeth and there
          is no upper age limit, which makes tooth whitening a fully accessible treatment for most people.</p>
      </div>
    </Layout>
  )
}

export default Page